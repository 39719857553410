<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="6">
              <el-page-header
              class="back"
                title="返回"
                @back="goBack"
                content="编辑产品详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: center" :span="12">
              <el-button :type="choose_tag === 0 ? 'warning' : ''" @click="choose_tag = 0"
                >富文本</el-button
              >
              <el-button :type="choose_tag === 1 ? 'warning' : ''" @click="choose_tag = 1"
                >代码</el-button
              >
            </el-col>
            <el-col style="text-align: right" :span="6">
              <el-button type="primary" icon="el-icon-check" @click="saveDetailModel"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <div v-show="choose_tag === 0" style="height: 100%; padding-top: 40px">
          <div id="rich-text-edit" class="rich-text-edit"></div>
          <div class="data-container">
            <el-form
              ref="form"
              style="margin: 10px"
              :rules="rules"
              :model="detail_model"
              label-width="80px"
            >
              <el-form-item label="标题:" prop="title">
                <el-input
                  v-model="detail_model.title"
                  placeholder="请输入标题"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="标题:">
                <el-select
                  v-model="detail_model.title"
                  placeholder="请选择"
                  loading-text="加载中..."
                  @change="toggleTitle($event)"
                >
                  <el-option
                    v-for="item in product_detail_map"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="权重:">
                <el-input-number
                  v-model="detail_model.number"
                  :min="0"
                  label="排序"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="主题:">
                <el-select
                  v-model="detail_model.theme"
                  placeholder="请选择"
                  loading-text="加载中..."
                >
                  <el-option
                    v-for="item in theme_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select
                  v-model="detail_model.status"
                  placeholder="请选择"
                  loading-text="加载中..."
                >
                  <el-option
                    v-for="item in $common.StatusSelectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="JS:">
                <el-input
                  v-model="rich_text_model.js"
                  placeholder="请输入JS链接"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="CSS:">
                <el-input
                  v-model="rich_text_model.css"
                  placeholder="请输入CSS链接"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="修改说明:">
                <el-input
                  v-model="detail_model.commit_info"
                  placeholder="请输入修改富文本的说明"
                  clearable
                ></el-input>
              </el-form-item>
              <CopyPaste
                :button_status="this.detail_id !== undefined && this.detail_id.length > 0"
                @copyInfo="copyInfo(detail_model)"
                @pasteInfo="pasteInfo"
                ref="copyPaste"
              ></CopyPaste>
            </el-form>

            <div style="padding: 0px 20px 0px 20px">
              <h3>详情历史列表:</h3>
              <dl>
                <dt
                  v-for="(item, index) in detail_model_list"
                  @click="switchHistoryContent(item, index)"
                  :key="index"
                >
                  <p
                    :style="index === detail_active ? 'color:#03AEF9;' : 'color:#333333;'"
                  >
                    {{ index + 1 + "." + item.commit_info }}
                  </p>
                </dt>
              </dl>
            </div>
          </div>
        </div>
        <div v-show="choose_tag === 1" style="height: 100%">
          <textarea
            id="code-view"
            class="code-view"
            v-model="rich_text_model.rich_text"
            @input="codeViewChange"
          ></textarea>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import wangEditor from "wangeditor";
import Hashids from "hashids";
import md5 from "js-md5";
import CopyPaste from "../../../../components/CopyPaste.vue";

export default {
  name: "ProductDetailRichTextTemplate",
  components: {
    CopyPaste,
  },
  data() {
    return {
      loading: false,
      editor: null,
      choose_tag: 0,
      theme_list: [
        {
          id: "black",
          name: "黑色",
        },
        {
          id: "white",
          name: "白色",
        },
      ],
      rules: {
        title: [
          { required: true, message: "请输入详情标题", trigger: "blur" },
          { min: 1, message: "详情标题大于 1 个字符", trigger: "blur" },
        ],
      },
      product_id: "",
      category_id: "",
      detail_id: "",
      detail_model: {
        product_id: "",
        category_id: "",
        title: "Text",
        theme: "black",
        module_json: "",
        number: 0,
        status: 2,
        commit_info: "",
      },
      rich_text_model: {
        rich_text: "",
        js: "",
        css: "",
      },
      detail_model_list: [],
      detail_active: 0,
      product_detail_map: [],
    };
  },
  mounted() {
    let _me = this;
    const editor = new wangEditor("#rich-text-edit");
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      _me.rich_text_model.rich_text = newHtml;
    };
    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500; // 修改为 500ms
    // 取消自动 focus(不自动焦点)
    editor.config.focus = false;
    // 自定义 Alert
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case "success":
          _me.$message.success(s);
          break;
        case "info":
          _me.$message.info(s);
          break;
        case "warning":
          _me.$message.warning(s);
          break;
        case "error":
          _me.$message.error(s);
          break;
        default:
          _me.$message.info(s);
          break;
      }
    };

    // 配置字体
    editor.config.fontNames = [
      // 对象形式 v4.6.16
      // 字符串形式
      // "黑体",
      // "仿宋",
      // "楷体",
      // "标楷体",
      // "华文仿宋",
      // "华文楷体",
      // "宋体",
      // "微软雅黑",
      // "Arial",
      // "Tahoma",
      // "Verdana",
      "Times New Roman",
      // "Courier New",
      // "Ubuntu",
    ];

    // 设置编辑区域高度为 500px
    // editor.config.height = 800
    // 配置菜单栏，设置不需要的菜单
    editor.config.excludeMenus = ["head", "emoticon"];
    // 配置上传图片类型
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    // 一次最多上传 1 个图片 - 2021年01月08日14:48:06
    editor.config.uploadImgMaxLength = 1;
    // 配置自定义实现上传图片
    editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      let hashids = new Hashids(_me.product_id);
      for (let i = 0; i < resultFiles.length; i++) {
        _me.loading = true;
        let file = resultFiles[i];
        let file_path = "www/files/v2/product/";
        let file_name_md5 = md5(new Date().getTime() + file.name);
        let file_name = hashids.encodeHex(file_name_md5);
        let file_type = file.type.substring(file.type.lastIndexOf("/") + 1);
        let key = file_path + file_name + "." + file_type; // 文件资源名
        _me.$common.qiniuUploadImage(
          file,
          key,
          function (res) {
            // console.log('已上传大小，单位为字节：' + res.total.loaded)
            // console.log('本次上传的总量控制信息，单位为字节：' + res.total.size)
            // eslint-disable-next-line no-console
            console.log("当前上传进度，范围：0～100：" + res.total.percent);
          },
          function (err, data) {
            // console.log(err, data)
            _me.loading = false;
            if (err === undefined) {
              // insertImgFn 是获取图片 url 后，插入到编辑器的方法
              // 上传图片，返回结果，将图片插入到编辑器中
              // eslint-disable-next-line
              insertImgFn(_me.$common.K_QINIU_DOMAIN + data.key);
            } else {
              _me.$message({
                message: err || "获取数据失败",
                type: "error",
              });
            }
          }
        );
      }
    };

    //配置格式刷
    // const { BtnMenu } = wangEditor;

    // class format extends BtnMenu {
    //   constructor(editor) {
    //     const $elem = wangEditor.$(
    //       `<div class="w-e-menu" data-title="格式刷">
    //     <i class="el-icon-s-open"></i>
    //   </div>`
    //     );
    //     super($elem, editor);
    //   }
    //   for_style(domE) {
    //     let arr = {};
    //     for (let i = 0; i < domE.length; i++) {
    //       arr[domE[i]] = domE[domE[i]];
    //     }
    //     return arr;
    //   }
    //   font_style(domE) {
    //     let arr = [];
    //     Array.from(domE.attributes).forEach((v) => {
    //       arr.push(`${v.name}='${v.value}'`);
    //     });
    //     return arr;
    //   }
    //   string_style(val) {
    //     let arr = "";
    //     for (const key in val) {
    //       if (val.hasOwnProperty(key)) {
    //         const element = val[key];
    //         arr += `${key}:${element};`;
    //       }
    //     }
    //     return arr;
    //   }
    //   format_style(style, text) {
    //     //设置模板
    //     let _html = "";
    //     for (let i = 0; i < style.length; i++) {
    //       if (i == 0) {
    //         if (style[0].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[0].style.length; index++) {
    //             arr += ` ${style[0].style[index]}`;
    //           }
    //           //   console.log(
    //           //     (_html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`)
    //           //   );
    //           _html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`;
    //         } else {
    //           _html = `<${style[0].html} style="${style[0].style}">${text}</${style[0].html}>`;
    //         }
    //       } else {
    //         if (style[i].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[i].style.length; index++) {
    //             arr += ` ${style[i].style[index]}`;
    //           }
    //           _html = `<${style[i].html}  ${arr}>${_html}</${style[i].html}>`;
    //         } else {
    //           _html = `<${style[i].html} style="${style[i].style}">${_html}</${style[i].html}>`;
    //         }
    //       }
    //     }
    //     return _html;
    //   }
    //   clickHandler(e, type) {
    //     //console.log(this.$elem);
    //     //console.log("格式刷被点击");
    //     //获取editor全局编辑对象
    //     const editor = this.editor;
    //     // 判断当前格式刷是否已经被激活
    //     // 如果是激活状态：关闭格式刷
    //     if (this.formatShow) {
    //       this.formatShow = false;
    //       editor._brush = false;
    //       // editor._dblBrush = false;
    //       this.$elem.removeClass("w-e-active");
    //       editor.$textContainerElem.removeClass("brush");
    //       //console.log("关闭格式刷");
    //       return;
    //     }
    //     // 如果当前状态是未激活
    //     // 将格式刷改成激活状态
    //     this.formatShow = true;
    //     editor._brush = true;
    //     // this.$elem.addClass('w-e-active')
    //     // 如果是双击格式刷触发连续使用格式刷
    //     // 记录双击格式刷状态
    //     // editor._dblBrush = type === "dblclick" ? true : false;
    //     this.$elem.addClass("w-e-active");
    //     editor.$textContainerElem.addClass("brush");
    //     //获取选中对象
    //     let containerEle = editor.selection.getSelectionContainerElem();
    //     editor._html = [];
    //     if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //       //console.log("有font");
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.font_style(containerEle.elems[0]),
    //       });
    //     } else {
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //       });
    //     }
    //     //向上整合style
    //     //如果是顶级p标签name将style放在wrap里面，否则直接整合进style
    //     while (!containerEle.equal(editor.$textElem)) {
    //       containerEle = containerEle.parent();
    //       //console.log("循环中", containerEle);
    //       if (
    //         containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         editor._html.push({
    //           html: containerEle.elems[0].tagName.toLowerCase(),
    //           style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //         });
    //       }
    //       if (
    //         !containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         //console.log("非顶点");
    //         if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //           //console.log("有font");
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.font_style(containerEle.elems[0]),
    //           });
    //         } else {
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //           });
    //         }
    //       }
    //     }
    //     // editor._html = [];
    //     // editor._html.push(containerEle.elems[0].tagName.toLowerCase());
    //     //console.log(editor);
    //     editor.$textElem.on("mouseup", (e) => {
    //       //  //console.log("监听可编辑区域", e);
    //       //  //console.log("当前选区", editor.saveSelection());
    //       editor.$textElem.off("mouseleave", editor.selection.saveRange());
    //       if (editor._brush) {
    //         let text;
    //         let style = "";
    //         let wrapStyle = "";
    //         //console.log("选区是否为空", editor.selection.isSelectionEmpty());

    //         if (editor.selection.isSelectionEmpty()) {
    //           // 如果没有选中任何内容
    //           // 折叠选区
    //           editor.selection.collapseRange();
    //         } else {
    //           let range = editor.selection.getRange();
    //           let containerElem = editor.selection.getSelectionContainerElem();
    //           // 如果只刷了一行
    //           if (!containerElem.equal(editor.$textElem)) {
    //             text = editor.selection.getSelectionText();
    //             let _html = this.format_style(editor._html, text);
    //             //渲染模板
    //             editor.cmd.do("insertHTML", _html);
    //           } else {
    //             let elements = [];
    //             let startElem = editor.selection.getSelectionStartElem();
    //             let startElemCon = startElem.parent().parent();
    //             let endElem = editor.selection.getSelectionEndElem();
    //             let endElemCon = endElem.parent().parent();

    //             elements.push({
    //               type: "start",
    //               elem: startElem,
    //               offset: range.startOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: startElemCon,
    //             });

    //             while (!startElemCon.next().equal(endElemCon)) {
    //               startElemCon = startElemCon.next();
    //               elements.push({
    //                 type: "mid",
    //                 elem: startElemCon,
    //                 container: startElemCon,
    //               });
    //             }

    //             elements.push({
    //               type: "end",
    //               elem: endElem,
    //               offset: range.endOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: endElemCon,
    //             });

    //             elements.forEach((element) => {
    //               let container = element.container;
    //               let range = editor.selection.createRangeByElem(container, null, true);

    //               if (element.type === "start") {
    //                 range.setStart(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "mid") {
    //                 text = range.toString();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "end") {
    //                 range.setEnd(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               }
    //             });
    //           }
    //         }
    //       }
    //     });
    //   }
    //   // 菜单激活状态
    //   tryChangeActive() {}
    //   //  菜单点击事件
    // }

    // const E_format = "format";

    // editor.menus.extend(E_format, format);
    // editor.config.menus.push(E_format);
    // 创建编辑器
    editor.create();
    // 赋值编辑器
    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  created() {
    this.product_id = this.$route.query.product_id;
    if (this.product_id === undefined || this.product_id.length === 0) {
      this.$message.error("数据错误,没获取到产品ID");
      this.goBack();
    }

    this.category_id = this.$route.query.category_id;
    if (this.category_id === undefined || this.category_id.length === 0) {
      this.$message.error("数据错误,没获取到分类ID");
      this.goBack();
    }

    this.detail_id = this.$route.query.id;
    if (this.detail_id !== undefined && this.detail_id.length > 0) {
      // 修改详情数据
      this.getDetailModel();
    }

    this.getDetailList();
  },
  methods: {
    getClassP(e) {
      if (e.parentNode.nodeName == "P") {
        return e.parentNode;
      } else {
        return this.getClassP(e.parentNode);
      }
    },
    codeViewChange() {
      let _me = this;
      // console.log("输入事件")
      _me.editor.txt.html(_me.rich_text_model.rich_text);
    },
    getDetailModel() {
      let _me = this;
      let params = {
        id: _me.detail_id,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/detail/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.detail_model = res.data;
            if (_me.detail_model.module_json.length > 0) {
              _me.rich_text_model = JSON.parse(_me.detail_model.module_json);
            }
            // rich_text是富文本的module_json字段里的，这里不需要富文本的字段，只需要module_json
            if (_me.rich_text_model.rich_text) {
              _me.editor.txt.html(_me.rich_text_model.rich_text);
            }

            _me.detail_model_list =
              res.data.history_list === undefined ? [] : res.data.history_list;
            // 将修改的说明复制到当前进行展示
            _me.detail_active = _me.detail_model_list.length - 1;
            // _me.detail_model.commit_info = _me.detail_model_list[_me.detail_active].commit_info;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    saveDetailModel() {
      if (this.detail_id !== undefined && this.detail_id.length > 0) {
        this.updateDetailModel();
      } else {
        this.newDetailModel();
      }
    },
    updateDetailModel() {
      let _me = this;
      let params = {
        id: _me.detail_id,
        product_id: _me.product_id,
        category_id: _me.category_id,
        title: _me.detail_model.title,
        theme: _me.detail_model.theme,
        module_json: JSON.stringify(_me.rich_text_model),
        number: _me.detail_model.number,
        status: _me.detail_model.status,
        commit_info: _me.detail_model.commit_info,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/detail/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.content_model = res.data;
            _me.$message.success("内容保存成功");
            _me.getDetailList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newDetailModel() {
      let _me = this;
      let params = {
        product_id: _me.product_id,
        category_id: _me.category_id,
        title: _me.detail_model.title,
        theme: _me.detail_model.theme,
        module_json: JSON.stringify(_me.rich_text_model),
        number: _me.detail_model.number,
        status: _me.detail_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/detail/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.content_model = res.data;
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getDetailList() {
      let _me = this;
      let params = {
        product_id: _me.product_id,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/detail/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            let array = [];
            if (res.data.array && res.data.array.length > 0) {
              for (let i of res.data.array) {
                if (i.category_name == "RichText") {
                  array.push(i);
                }
              }
              _me.product_detail_map = array;
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    toggleTitle($event) {
      for (let i of this.product_detail_map) {
        if (i.title == $event) {
          window.location.replace(
            "/product-detail-template-rich-text?product_id=" +
              i.product_id +
              "&category_id=" +
              i.category_id +
              "&id=" +
              i.id
          );
        }
      }
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);

      //   this.$router.push("product-detail?id=" + this.product_id);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    switchHistoryContent(model, index) {
      let _me = this;
      //   将之前版本的module_json进行赋值到当前富文本，并且解析
      _me.detail_model.module_json = model.module_json;
      //   将修改的说明也赋值到当前的富文本进行展示
      //   _me.detail_model.commit_info = model.commit_info;
      _me.detail_active = index;
      // 解析module_json的富文本
      _me.rich_text_model = JSON.parse(_me.detail_model.module_json);
      if (_me.rich_text_model.rich_text) {
        _me.editor.txt.html(_me.rich_text_model.rich_text);
      }
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.detail_model = model;
      if (model.module_json.length > 0) {
        this.rich_text_model = JSON.parse(model.module_json);
        // rich_text是富文本的module_json字段里的，这里不需要富文本的字段，只需要module_json
        if (this.rich_text_model.rich_text) {
          this.editor.txt.html(this.rich_text_model.rich_text);
        }
      }
    },
  },
};
</script>

<style>
.w-e-text-container {
  /*height: 100% !important;*/
  min-height: calc(100vh - 210px);
  text-align: left;
}
</style>

<style scoped>
.banner-head {
  /* margin-bottom: 0; */
  background-color: #f1f1f1;
  height: 60px;
}

.head-info {
  margin-top: 10px;
  color: #ffffff;
}

.rich-text-edit {
  width: 75%;
  height: 100%;
  float: left;
}

.rich-text-edit >>> .w-e-toolbar {
  z-index: 2000 !important;
}

.rich-text-edit >>> .w-e-text-container {
  z-index: 1999 !important;
}

.data-container {
  width: 25%;
  height: 100%;
  float: right;
  text-align: left;
  /*background-color: #d8365f;*/
}

.code-view {
  width: 100%;
  height: 100%;
  text-align: left;
  resize: none;
}
</style>
