<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="分类:">
              <el-select
                v-model="detail_category_id"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option key label="所有" value></el-option>
                <el-option
                  v-for="item in detail_category_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getDetailList"
            >过滤</el-button>
            <el-button class="top-button" size="medium" type="primary" round @click="newOnClick">添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="detail_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold;">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="标题" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold;">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <el-table-column label="分类" align="center">
            <template slot-scope="scope">
              <p
                style="font-weight: bold;"
              >{{detail_category_map.get(scope.row.category_id)?detail_category_map.get(scope.row.category_id).name:'无'}}</p>
            </template>
          </el-table-column>
          <el-table-column label="最后修改时间" align="center">
            --/0-+
            <template slot-scope="scope">
              <p style="font-weight: bold;">{{ $common.formatTimesToTime(scope.row.updated_at) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="160" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold;">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button style="color: #03AEF9" @click="editDetailOnclick(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeDetailOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px;"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  // 产品详情 - 内容(富文本)页面
  name: "ProductDetailContentModule",
  props: {
    productId: {
      type: String,
      default: ""
    },
    operateType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      detail_list: [],
      detail_category_id: "",
      detail_category_list: [],
      detail_category_map: {}
    };
  },
  created() {
    this.detail_category_map = new Map();
    this.getDetailCategoryList();
    if (this.productId.length > 0) {
      this.getDetailList();
    }
  },
  methods: {
    newOnClick() {
      // this.$router.push('/product-detail-content?product_id=' + this.productId)
      let category_name = this.detail_category_map.get(this.detail_category_id)
        ? this.detail_category_map.get(this.detail_category_id).name
        : "";
      if (category_name === "") {
        this.$message.error("请先选择产品详情的分类");
      } else {
        let _me = this;
        switch (category_name) {
          case "RichText":
            this.$router.push(
              "/product-detail-template-rich-text?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          case "Features":
            this.$router.push(
              "/product-detail-template-features?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          case "360°":
            this.$router.push(
              "/product-detail-template-3d?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          case "Colors":
            this.$router.push(
              "/product-detail-template-colors?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          case "Specifications":
            this.$router.push(
              "/product-detail-template-specifications?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          case "Gallery":
            this.$router.push(
              "/product-detail-template-gallery?product_id=" +
                this.productId +
                "&category_id=" +
                this.detail_category_id
            );
            break;
          default:
            _me.$message.error("未知的模板,请联系软件部前端开发人员添加模板");
            break;
        }
      }
    },
    getDetailCategoryList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: ""
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/category/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.detail_category_list =
              res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.detail_category_list) {
              _me.detail_category_map.set(i.id, i);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getDetailList() {
      let _me = this;
      let params = {
        product_id: this.productId,
        key: this.search_key,
        status: this.page_status,
        page_size: this.page_size,
        page_number: this.page_number,
        sort_fields: ""
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.detail_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getDetailList();
    },
    editDetailOnclick(model) {
      // this.$router.push('/product-detail-content?product_id=' + model.product_id + "&id=" + model.id)
      let _me = this;
      switch (model.category_name) {
        case "RichText":
          this.$router.push(
            "/product-detail-template-rich-text?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        case "Features":
          this.$router.push(
            "/product-detail-template-features?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        case "360°":
          this.$router.push(
            "/product-detail-template-3d?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        case "Colors":
          this.$router.push(
            "/product-detail-template-colors?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        case "Specifications":
          this.$router.push(
            "/product-detail-template-specifications?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        case "Gallery":
          this.$router.push(
            "/product-detail-template-gallery?product_id=" +
              this.productId +
              "&category_id=" +
              model.category_id +
              "&id=" +
              model.id
          );
          break;
        default:
          _me.$message.error("未知的模板,请联系软件部前端开发人员添加模板");
          break;
      }
    },
    removeDetailOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      this.$common
        .httpPost("/api/product/admin/detail/delete", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("删除成功");
            _me.getDetailList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped></style>