<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                :content="product_id ? '产品详情' : '新增产品'"
              ></el-page-header>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-tabs type="card" :stretch="true" :before-leave="leaveTabOnclick">
          <el-tab-pane label="基本信息" name="0">
            <ProductDetailInfoModule
              :product-id="product_id"
              :operate-type="operate_type"
              ref="ProductDetailInfoModule"
            />
          </el-tab-pane>
          <el-tab-pane label="详情信息" name="1">
            <ProductDetailContentModule
              :product-id="product_id"
              :operate-type="operate_type"
              ref="ProductDetailContentModule"
            />
          </el-tab-pane>
          <el-tab-pane label="SKU" name="2">
            <ProductDetailSkuModule
              :product-id="product_id"
              :operate-type="operate_type"
              ref="ProductDetailSkuModule"
              v-if="is_list"
              :sku-id="sku_id"
              :switchSkuHandle="switchSku"
            />
            <ProductSkuListModule
              :product-id="product_id"
              :operate-type="operate_type"
              ref="ProductSkuListModule"
              v-else
              :switchSkuHandle="switchSku"
            />
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import ProductDetailInfoModule from "./module/ProductDetailInfoModule";
import ProductDetailContentModule from "./module/ProductDetailContentModule";

import ProductSkuListModule from "./module/ProductSkuListModule.vue";
import ProductDetailSkuModule from "./module/ProductDetailSkuModule.vue";

export default {
  name: "ProductDetailView",
  components: {
    ProductDetailInfoModule,
    ProductDetailContentModule,
    ProductSkuListModule,
    ProductDetailSkuModule
  },
  data() {
    return {
      loading: false,
      product_id: "",
      operate_type: "edit",
      is_list: false,
      sku_id: ""
    };
  },
  created() {
    this.product_id = this.$route.query.id;
    // if (this.product_id == undefined || this.product_id.length == 0) {
    //   this.$message.error("没获取到ID");
    // }
  },
  methods: {
    leaveTabOnclick(activeName, oldActiveName) {
      // eslint-disable-next-line no-console
      console.log(activeName, oldActiveName);
      let _me = this;
      // eslint-disable-next-line no-console
      if (activeName == 2) {
        _me.is_list = false;
      }
      if (_me.product_id !== undefined && _me.product_id.length > 0) {
        return true;
      }
    },
    switchSku(value) {
      let _me = this;
      _me.is_list = !_me.is_list;
      _me.sku_id = value;
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    }
  }
};
</script>

<style scoped>
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
</style>
