<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="产品类型:">
              <el-select v-model="product_type" clearable placeholder="请选择" loading-text="加载中...">
                <el-option :label="'全部类型'" value="0"></el-option>
                <el-option
                  v-for="item in product_type_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getProductList"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getProductList"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail"
            >新建</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table :data="product_list" style="width: 100%" border default-expand-all>
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="名称" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="PC端图标" width="160" align="center">
            <template slot-scope="scope">
              <el-image
                fit="contain"
                style="width: 100px; height: 100px"
                :src="scope.row.pc_banner + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.pc_banner]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="WAP端图标" width="160" align="center">
            <template slot-scope="scope">
              <el-image
                fit="contain"
                style="width: 100px; height: 100px"
                :src="scope.row.wap_banner + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.wap_banner]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="链接" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.alias }}</p>
            </template>
          </el-table-column>
          <el-table-column label="类型" width="160" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                product_type_map.get(scope.row.type_id)
                ? product_type_map.get(scope.row.type_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="发布时间" width="120" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.created_at) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="checkModelDetail(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeProductOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "ProductView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      product_type: "0",
      product_type_list: [],
      product_type_map: {},
      product_list: []
    };
  },
  created: function() {
    let _me = this;
    _me.product_type_map = new Map();
    _me.getProductTypeList();
    _me.getProductList();
  },
  methods: {
    getProductTypeList() {
      let _me = this;
      let key = "productTypeList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.product_type_list = val;
        for (let i of _me.product_type_list) {
          _me.product_type_map.set(i.id, i);
        }
        return;
      }

      let params = {
        page_size: 20,
        page_number: 1,
        sort_fields: "number"
      };
      _me.loading = true;
      _me.api
        .get_product_type_list(params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.product_type_list =
              res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.product_type_list) {
              _me.product_type_map.set(i.id, i);
            }
            _me.$common.setGlobalStorage(key, _me.product_type_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getProductList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        type_id: _me.product_type,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: ""
      };
      _me.loading = true;
      _me.api
        .get_product_list(params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.product_list =
              res.data.array == undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getProductList();
    },
    checkModelDetail(model) {
      let _me = this;
      let path =
        model && model.id ? "product-detail?id=" + model.id : "product-detail";
      _me.$router.push(path);
    },
    copyProductOnclick(product_model) {
      // eslint-disable-next-line no-console
      // console.log("复制产品:", product_model)
      this.$router.push("product-copy?id=" + product_model.id);
    },
    removeProductOnclick(product_model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(product_model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(product_model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: product_model.id
      };
      _me.api
        .delete_product(params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("删除成功");
            _me.getProductList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped></style>
